<template>
  <el-dialog
    title="资源匹配"
    :visible.sync="showMatch"
    width="900px"
    :closeOnClickModal="false"
    @open="initData"
    :before-close="closeFn"
  >
    <div class="flex-end addBtn">
      <el-button type="primary" size="mini" @click="addFn">新增</el-button>
    </div>
    <el-table
      :data="tableList"
      style="width: 100%;"
      max-height="400px"
      :stripe="true"
      border
      :default-expand-all="true"
      :header-cell-style="{ 'text-align': 'center ' }"
      :cell-style="{ 'text-align': 'center ' }"
    >
      <el-table-column label="资源匹配类型">
        <template slot-scope="{ row }">
          <el-select
            v-model="form.type"
            placeholder="请选择匹配类型"
            clearable
            v-if="row.isEdit"
          >
            <el-option
              v-for="(item, idx) in typeList"
              :key="idx"
              :label="item"
              :value="idx"
            >
            </el-option>
          </el-select>
          <span v-else>{{ typeList[row.type] }}</span>
        </template>
      </el-table-column>
      <el-table-column label="资源匹配模式" min-width="150px">
        <template slot-scope="{ row }">
          <el-input
            v-if="row.isEdit"
            v-model.trim="form.pattern"
            placeholder="请输入资源匹配模式"
            clearable
            maxlength="100"
            show-word-limit
            class="inputWidth"
          />
          <span v-else>{{ row.pattern }}</span></template
        >
      </el-table-column>
      <el-table-column label="资源匹配是否大小写敏感">
        <template slot-scope="{ row }">
          <div v-if="!row.isEdit">
            <span v-if="row.caseSensitive">是</span
            ><span v-if="!row.caseSensitive">否</span>
          </div>

          <el-radio-group v-model="form.caseSensitive" v-else>
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{ row }">
          <el-link
            class="links"
            type="primary"
            v-show="!row.isEdit"
            @click="optionFn('edit', row)"
            >编辑</el-link
          >
          <el-popconfirm title="确定删除吗？" @confirm="sureDelteFn(row)">
            <el-link
              slot="reference"
              class="links"
              type="primary"
              v-show="!row.isEdit"
              >删除</el-link
            >
          </el-popconfirm>

          <el-link
            class="links"
            type="primary"
            v-show="!!row.isEdit"
            @click="optionFn('sure', row)"
            >确定</el-link
          >
          <el-link
            class="links"
            type="primary"
            v-show="!!row.isEdit"
            @click="optionFn('cancel', row)"
            >取消</el-link
          >
        </template></el-table-column
      >
    </el-table>
  </el-dialog>
</template>

<script>
import {
  search_apply_math,
  add_edit_appSource_match,
  remove_appSource_match,
} from '@/api/apply'

export default {
  name: 'apply-source-match',
  props: {
    showMatch: false,
    tenantPermissionId: '',
  },
  data() {
    return {
      tableList: [],
      form: { caseSensitive: '1', type: '', pattern: '' },
      typeList: {
        ANY: '任意',
        EQUAL: '一致',
        ANT_PATH: 'Ant 路径',
        REGEX: '正则',
      },
      visible: false,
    }
  },
  mounted() {},
  methods: {
    initData() {
      this.search_apply_math()
    },
    async search_apply_math() {
      const res = await search_apply_math({
        tenantPermissionId: this.tenantPermissionId,
      })
      this.tableList = res.map((cell) => {
        return {
          ...cell,
          isEdit: false,
        }
      })
    },
    optionFn(type, row) {
      // return
      if (type == 'cancel') {
        if (row.tenantPermissionResourceMatchId) {
          //编辑
          row.isEdit = false
        } else {
          //新增

          this.tableList.shift()
          this.form = { caseSensitive: '1', type: '', pattern: '' }
        }
      } else if (type == 'sure') {
        let msg = !row.tenantPermissionResourceMatchId ? '新增成功' : '保存成功'
        this.add_edit_appSource_match(row, () => {
          row.isEdit = false
          this.$message.success(msg)
          this.search_apply_math()
        })
      } else if (type == 'edit') {
        const hasEdit = this.tableList.some((cell) => {
          return cell.isEdit
        })
        if (hasEdit) {
          this.$message.warning('请完成编辑')
          return
        }
        row.isEdit = true
        this.form.type = row.type
        this.form.pattern = row.pattern
        this.form.caseSensitive = row.caseSensitive + ''
      }
    },
    sureDelteFn(row) {
      this.visible = false
      remove_appSource_match({
        tenantPermissionResourceMatchId: row.tenantPermissionResourceMatchId.toString(),
      }).then(() => {
        this.$message.success('删除成功')
        this.search_apply_math()
      })
    },
    async add_edit_appSource_match(row, cb) {
      let f_ = { ...this.form }
      if (!f_.type) {
        this.$message.warning('请选择资源匹配类型')
        return
      } else if (f_.pattern == '') {
        this.$message.warning('请输入资源匹配模式')
        return
      }
      f_.caseSensitive = Number(f_.caseSensitive)
      let prams = row.tenantPermissionId ? { ...row, ...f_ } : { ...f_ }
      const res = await add_edit_appSource_match({
        tenantPermissionId: this.tenantPermissionId,
        ...prams,
        disabled: 0,
      })
      cb && cb()
    },
    addFn() {
      const hasEdit = this.tableList.some((cell) => {
        return cell.isEdit
      })
      if (hasEdit) {
        this.$message.warning('请完成编辑')
        return
      }
      let a = { caseSensitive: '1', type: '', pattern: '', isEdit: true }
      this.form = { caseSensitive: '1', type: '', pattern: '' }
      this.tableList.unshift(a)
    },
    closeFn() {
      this.$emit('closeFn')
    },
  },
}
</script>

<style lang="scss" scoped>
.addBtn {
  margin-bottom: 10px;
}
.links {
  margin-right: 10px;
  ::last-child {
    margin-right: 0;
  }
}
</style>
